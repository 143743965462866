<template>
    <div class="edit-chapter-global page-container flex-column" v-scrollbar.y>
        <editChapter ref="chapter" :currentChapterData="currentChapterData"/>
        <paginationPlus ref="pagination" v-if="!$route.query.single" :currentPage.sync="pagination.pageIndex" :pageSizes="pagination.pageSizes" :layout="pagination.layout" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-21 15:58:27
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-19 11:42:44
 * @Description: 编辑漫画的某一章节-全局
 * @Company: 成都二次元动漫
 */
import editChapter from './editChapter';
// import { getRouteByName } from '@/router/helper';
import { paginationMixin, dataMixin } from '@/mixins';

export default {
    mixins: [paginationMixin, dataMixin],
    data() {
        return {
            pagination: {
                pageIndex: 1,
                pageSize: 1,
                total: 0,
                pageSizes: [1],
                layout: 'total, prev, pager, next, jumper'
            },
            decompressImageList: [],
            isWarn: false,
            currentChapterData: {
                type: 'add',
                chapterId: null,
                uploadId: null,
                isBatch: true,
                chapterSN: null,
                imageAddr: []
            },
            createdChapterMap: {} // 已编辑并提交的章节
        };
    },
    computed: {
        // 未编辑提交的章节
        uncreateChapter() {
            const that = this;
            return this.decompressImageList.reduce((arr, newChapter, index) => {
                const isCreated = Object.keys(that.createdChapterMap).some(page => ((index + 1) === (page - 0)));
                if (!isCreated) {
                    newChapter = that.$utils.cloneDeep(newChapter);
                    newChapter.pageIndex = index + 1;
                    arr.push(newChapter);
                }
                return arr;
            }, []);
        }
    },
    beforeCreate() {
        const { commit, state } = this.$store;
        const { comicName } = this.$route.query;
        // let auditComicListRoute = getRouteByName('editComic')[0];
        // auditComicListRoute.title = '章节列表';
        // auditComicListRoute = {
        //     ...auditComicListRoute,
        //     query: {
        //         comicId,
        //         comicName,
        //         editType: 'edit',
        //         tabtype: 2
        //     }
        // };
        // state.app.breadcrumb.splice(-1, 1, auditComicListRoute);
        // if ([2, 3].includes(this.$store.state.app.userInfo.level)) state.app.breadcrumb.splice(1, 1);
        if (state.app.breadcrumb[2].name === 'editComicChapter') state.app.breadcrumb.splice(2);
        commit('app/setBreadcrumb', [...state.app.breadcrumb, { title: comicName }, { title: '编辑章节' }]);
    },
    created() {
        if (!this.$route.query.single) this.getDecompressChapters();
    },
    methods: {
        // 获取解压章节列表
        getDecompressChapters() {
            const { comicId, originFile = null } = this.$route.query;
            this.$api('getDecompressChapter', { comicId, originFile }).then(res => {
                this.decompressImageList = res.data.rows || [];
                this.pagination.total = res.data.rows.length;
                this.setChapterImageAddr(0);
            });
        },
        // 设置当前新章节的内容图片数据
        setChapterImageAddr(index) {
            const { chapterSN, images, uploadId } = this.decompressImageList[index];
            const imageAddr = images.map(item => item.imageAddr);
            this.currentChapterData = {
                ...this.currentChapterData,
                uploadId,
                chapterSN,
                imageAddr
            };
        },
        // 批量编辑章节下未编辑完，的提示语言
        batchUncreateMessage() {
            if (!this.uncreateChapter.length) return;
            const messageList = this.uncreateChapter.map((chapter, index) => {
                return `<strong>页数：</strong>${chapter.pageIndex}<strong>  章节编号：</strong>${chapter.chapterSN}`;
            });
            this.$notify.info({
                title: '未编辑章节',
                dangerouslyUseHTMLString: true,
                message: messageList.join('<br />')
            });
        },
        // 分页
        pageChangeHandle({ page, size, prevPage }) {
            if (this.$refs.chapter.isChange && !this.$refs.chapter.isSubmit && !this.isWarn) {
                this.isWarn = true;
                this.$nextTick(() => {
                    this.pagination.pageIndex = prevPage;
                    this.isWarn = false;
                });
                return this.$message.warning('请先保存编辑内容!');
            }

            this.resetDataField('currentChapterData');
            // 在多章节编辑模式下，如果next页已经保存过（生产了chapterId）则切换为编辑模式，否则就保存prev页面的chapterId
            if (this.createdChapterMap[page]) {
                this.currentChapterData.type = 'edit';
                this.currentChapterData.chapterId = this.createdChapterMap[page];
            } else if (!this.createdChapterMap[page] && this.$refs.chapter.isSubmit) {
                this.createdChapterMap = {
                    ...this.createdChapterMap,
                    [prevPage]: this.$utils.cloneDeep(this.$refs.chapter.prevChapterId)
                };
            }

            document.querySelector('.edit-chapter form').scrollTop = 0;

            this.batchUncreateMessage();

            this.isWarn = false;
            this.setChapterImageAddr(page - 1);
            this.pagination.pageIndex = page;
        }
    },
    components: {
        editChapter
    }
};
</script>

<style lang="scss" scoped>
    .edit-chapter-global{
        height: 100%;
        padding: 30px;
        position: relative;
        overflow: hidden;
        background: #ffffff;
        &.page-container{
            padding: 20px 20px 0;
        }
    }
</style>
